/* ======================*/

/* Epic Realm
/* created by Nauman

@charset "utf-8";

Table of content:

* color
* font
* general
* margin padding
* preloader
* main section
* header
* dropdown
* swiper slider
* smallswiper
* home static
* home static video
* testimonial
* server
* collection
* accordion
* location map
* marquee
* pricelist
* de_pricing-table
* blog list
* pagination list
* to top
* knowledge
* footer section
* contact
* login
* media query
*/

/* ======================*/

/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Oxanium:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

/* color */
$white: #fff;
$gray: #bbb;
$black: #171a1d;
$general: #727272;
$black_soft: #333;
$black_col: #2e333c;
$black_more: #0f0f0f;
$color: #5623d8;
$color_more: #bc1c1c;

/* font */
* {
  --title-font: "Oxanium", Helvetica, Arial, sans-serif;
  --body-font: "Manrope", Helvetica, Arial, sans-serif;
  --border-default: solid 1px rgba(30, 30, 30, 1);
  --bg-color-even: #e8e8e8;
  --bg-color-odd: #f4f4f4;
  --bg-dark-1: #1e1f22;
  --bg-dark-2: #252629;
  --bg-dark-3: #303030;
  --body-font-color: #757575;
  --dark-body-font-color: #adb7be;
  --swiper-theme-color: var(--secondary-color);
  --primary-color: #5623d8;
  --primary-color-rgb: 86, 35, 216;
  --secondary-color: #6a79fa;
  --secondary-color-rgb: 106, 121, 250;
  --tertiary-color: #e615af;
  --tertiary-color-rgb: rgba(230, 21, 175, 1);
}

/* general */
html {
  scroll-behavior: auto;
}
body {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 500;
  color: var(--body-font-color);
  background: var(--bg-dark-1);
  line-height: 30px;
  padding: 0;
  line-height: 1.8em;
  word-spacing: 0px;
  letter-spacing: -0.2px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.ohidden {
    overflow: hidden !important;
  }
}

.col-white {
  color: $white;
}
.color {
  color: $color;
}
.transition {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
h1,
h2,
h4,
h3,
h5,
h6,
.h1_big,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
footer.footer-light h5 {
  margin-top: 0;
  font-family: var(--title-font);
  font-weight: bold;
  color: #fff;
}
.h1_big {
  color: $white;
  position: relative;
  left: -3px;
  top: -10px;
  font-size: 64px;
  line-height: 70px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 34px;
  line-height: 42px;
}
.spacer-10 {
  width: 100%;
  height: 10px;
  display: block;
  clear: both;
}
.spacer-20 {
  width: 100%;
  height: 20px;
  display: block;
  clear: both;
}
.spacer-30 {
  width: 100%;
  height: 30px;
  display: block;
  clear: both;
}
.spacer-half {
  width: 100%;
  height: 15px;
  display: block;
  clear: both;
}

/* margin padding */
.mt0 {
  margin-top: 0;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mt70 {
  margin-top: 70px;
}

.mt80 {
  margin-top: 80px;
}

.mt90 {
  margin-top: 90px;
}

.mt100 {
  margin-top: 100px;
}

.mb0 {
  margin-bottom: 0;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb100 {
  margin-bottom: 100px;
}

.mb0 {
  margin-bottom: 0;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb100 {
  margin-bottom: 100px;
}

.ml0 {
  margin-left: 0;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.ml40 {
  margin-left: 40px;
}

.ml50 {
  margin-left: 50px;
}

.ml60 {
  margin-left: 60px;
}

.ml70 {
  margin-left: 70px;
}

.ml80 {
  margin-left: 80px;
}

.ml90 {
  margin-left: 90px;
}

.ml100 {
  margin-left: 100px;
}

.mr0 {
  margin-right: 0;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.mr40 {
  margin-right: 40px;
}

.mr50 {
  margin-right: 50px;
}

.mr60 {
  margin-right: 60px;
}

.mr70 {
  margin-right: 70px;
}

.mr80 {
  margin-right: 80px;
}

.mr90 {
  margin-right: 90px;
}

.mr100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: -100px;
}

.mb-200 {
  margin-bottom: -200px;
}

.padding10 {
  padding: 10px;
}

.padding20 {
  padding: 20px;
}

.padding30 {
  padding: 30px;
}

.padding40 {
  padding: 40px;
}

.padding50 {
  padding: 50px;
}

.padding60 {
  padding: 60px;
}

.padding70 {
  padding: 70px;
}

.padding80 {
  padding: 80px;
}

.padding100 {
  padding: 100px;
}

.pt0 {
  padding-top: 0;
}

.pt10 {
  padding-top: 10px;
}

.pt20 {
  padding-top: 20px;
}

.pt30 {
  padding-top: 30px;
}

.pt40 {
  padding-top: 40px;
}

.pt50 {
  padding-top: 50px;
}

.pt60 {
  padding-top: 60px;
}

.pt70 {
  padding-top: 70px;
}

.pt80 {
  padding-top: 80px;
}

.pt90 {
  padding-top: 90px !important;
}

.pb0 {
  padding-bottom: 0;
}

.pb10 {
  padding-bottom: 10px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb50 {
  padding-bottom: 50px;
}

.pb60 {
  padding-bottom: 60px;
}

.pb80 {
  padding-bottom: 80px;
}

.pl130 {
  padding-left: 130px;
}

.pl30 {
  padding-left: 30px;
}

.pl40 {
  padding-left: 40px;
}

.pr20 {
  padding-right: 20px;
}

.pr30 {
  padding-right: 30px;
}

.pr40 {
  padding-right: 40px;
}

.mt-10 {
  margin-top: -10px;
}

.mt-20 {
  margin-top: -20px;
}

.mt-30 {
  margin-top: -30px;
}

.mt-40 {
  margin-top: -40px;
}

.mt-50 {
  margin-top: -50px;
}

.mt-60 {
  margin-top: -60px;
}

.mt-70 {
  margin-top: -70px;
}

.mt-80 {
  margin-top: -80px;
}

.mt-90 {
  margin-top: -90px;
}

.mt-100 {
  margin-top: -100px;
}

.mb-sm-20 {
  margin-bottom: 20px;
}

.mb-sm-40 {
  margin-bottom: 40px;
}

.mb-sm-50 {
  margin-bottom: 50px;
}

.mb-sm-60 {
  margin-bottom: 60px;
}

.sm-mt-0 {
  margin-top: 0;
}

.p-sm-30 {
  padding: 40px;
}

.pb-sm-0 {
  padding-bottom: 0;
}

.mb-sm-0 {
  margin-bottom: 0;
}

.w-100px {
  width: 100px;
}

.w-200px {
  width: 200px;
}

.absolute {
  position: absolute;
  z-index: 1;
}

.relative {
  position: relative;
  z-index: 1;
}

.z-1 {
  position: relative !important;
  z-index: 8;
}

.z-9 {
  position: relative !important;
  z-index: 9;
}

.z-1000 {
  position: relative !important;
  z-index: 1000;
}

.overflow-hidden {
  overflow: hidden;
}

.width100 {
  width: 100%;
}

.border1 {
  border-width: 1px;
}

.bordertop {
  border-top: solid 1px #eee;
}

.borderbottom {
  border-bottom: solid 1px #eee;
}

.pos-top {
  position: relative;
  z-index: 1000;
}

.op-4 {
  opacity: 0.4;
}

.op-5 {
  opacity: 0.5;
}

.rounded-10 {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.bg-half-white {
  background: rgba(255, 255, 255, 0.05);
}


/* preloader */
#de-loader {
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #222;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--primary-color);
  margin: -2px 0 0 -2px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#root,
.App {
  background: #000;
}
#routerhang {
  transform: unset !important;
  transform-origin: unset !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.burgermenu {
  width: 32px;
  height: 32px;
  padding: 4px;
  text-align: center;
  font-size: 20px;
  display: none;
  position: relative;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  color: $white;
  transition: 0.5s;
  &:hover {
    background: none;
    color: $white;
    transition: 0.5s;
  }
  i {
    position: relative;
    top: -2px;
  }
}

/* main section */
.dark-scheme section {
  background: var(--bg-dark-1);
  .subtitle {
    position: relative;
    display: inline-block;
    font-weight: bold;
    color: #ffffff;
    border: solid 2px rgba(255, 255, 255, 0.25);
    padding: 0 15px 2px 15px;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
  }
  h2 {
    font-size: 52px;
    margin-bottom: 5px;
    line-height: 1.1em;
    letter-spacing: -0.025em;
    padding-bottom: 5px;
    background: -webkit-linear-gradient(0deg, #888888 0%, #ffffff 75%);
    background: -moz-linear-gradient(0deg, #888888 0%, #ffffff 75%);
    background: linear-gradient(0deg, #888888 0%, #ffffff 75%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.react-parallax {
  background: var(--bg-dark-1);
}
.bgcustom {
  background: rgba(0, 0, 0, 0.3) 0% 0% / cover no-repeat;
}
section,
.section {
  position: relative;
  padding: 120px 0;
  width: 100%;
  height: auto;
  background: #171a1d;
  background-size: cover;
  background-position-y: center;
  &.bg-bottom {
    &:before {
      position: absolute;
      bottom: 0px;
      left: 0;
      content: "";
      width: 100%;
      height: 50%;
      background-image: linear-gradient(
        rgba(23, 26, 29, 0),
        rgba(23, 26, 29, 1)
      );
      z-index: 1;
    }
  }
  &.bg-top {
    &:after {
      position: absolute;
      top: 0px;
      left: 0;
      content: "";
      width: 100%;
      height: 50%;
      background-image: linear-gradient(
        rgba(23, 26, 29, 1),
        rgba(23, 26, 29, 0)
      );
      z-index: 1;
    }
  }
  .react-parallax-bgimage {
    object-fit: cover;
    top: -150px;
  }
  .bgparalax {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .avatar {
    right: 0px;
    bottom: 0px;
  }
  &.no-padding {
    padding: 0;
  }
  &.no-top {
    margin-top: 0;
    padding-top: 0;
  }
  &.no-bottom {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  &.no-bg {
    background: none;
    position: relative;
    z-index: 2;
  }
  img.max-content {
    width: max-content;
    padding: 0;
  }
}
.subtitle {
  position: relative;
  display: inline-block;
  font-weight: bold;
  color: #ffffff;
  border: solid 2px rgba(255, 255, 255, 0.25);
  padding: 0 15px 2px 15px;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
}
h2 {
  font-size: 52px;
  margin-bottom: 5px;
  line-height: 1.1em;
  letter-spacing: -0.025em;
  padding-bottom: 5px;
  background: -webkit-linear-gradient(0deg, #888888 0%, #ffffff 75%);
  background: -moz-linear-gradient(0deg, #888888 0%, #ffffff 75%);
  background: linear-gradient(0deg, #888888 0%, #ffffff 75%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.py-5.position-relative {
  z-index: 2;
}
.spacer-single {
  width: 100%;
  height: 30px;
  display: block;
  clear: both;
}
.spacer-double {
  width: 100%;
  height: 60px;
  display: block;
  clear: both;
}
.v-center {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.list_location,
.list_location li {
  list-style: none;
  padding: 0;
}
.list_location li {
  color: #bbbbbb;
  display: inline-block;
  margin-right: 35px;
  font-size: 14px;
  border-left: solid 1px rgba(0, 0, 0, 0.25);
  border-left-color: rgba(255, 255, 255, 0.25);
  padding-left: 10px;
  span {
    font-family: var(--title-font);
    color: $white;
    font-size: 16px;
    font-weight: bold;
    display: block;
  }
}
.float-text {
  width: 20px;
  position: fixed;
  z-index: 1002;
  margin-left: 30px;
  text-align: center;
  letter-spacing: 2px;
  font-size: 12px;
  top: 50%;
  left: 15px;
  opacity: 1;
  transform: translate(-50%, -50%);
  span {
    margin-left: -5px;
    margin-top: 20px;
    writing-mode: vertical-rl;
    letter-spacing: 0.75px;
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    color: rgba(255, 255, 255, 0.5);
  }
  .de_social-icons {
    span.buton {
      position: relative;
      left: 1px;
      top: 1px;
      color: $color;
      cursor: pointer;
      margin-top: 0;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
}
.space-border {
  width: 30px;
  height: 1px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 0px;
  margin-bottom: 40px;
  background: $color;
  &:before {
    width: 30px;
    height: 1px;
    content: "";
    position: absolute;
    left: -40px;
    background: rgba(255, 255, 255, 0.25);
  }
  &:after {
    width: 30px;
    height: 1px;
    content: "";
    position: absolute;
    right: -40px;
    background: rgba(255, 255, 255, 0.25);
  }
}

a.btn-main,
.btn-main {
  display: inline-block;
  font-family: var(--title-font);
  text-align: center;
  color: #fff;
  outline: 0;
  font-weight: bold;
  text-decoration: none;
  padding: 3px 20px 2px 20px;
  font-size: 14px;
  border: none;
  text-transform: uppercase;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  transition: all 0.3s ease;
  &:hover {
    color: #fff;
    -webkit-box-shadow: 0 0 40px 0px rgba(var(--primary-color-rgb), 1);
    -moz-box-shadow: 0 0 40px 0px rgba(var(--primary-color-rgb), 1);
    box-shadow: 0 0 40px 0px rgba(var(--primary-color-rgb), 1);
    transition: all 0.3s ease;
  }
  &.btn-fullwidth {
    display: block;
    width: 100%;
  }
}
a.btn-line {
  display: inline-block;
  font-family: var(--title-font);
  text-align: center;
  color: #fff;
  background: none;
  outline: 0;
  font-weight: bold;
  text-decoration: none;
  padding: 3px 20px 2px 20px;
  font-size: 14px;
  border: none;
  text-transform: uppercase;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: solid 2px var(--primary-color);
  transition: all 0.3s ease;
  &:hover {
    background: var(--primary-color);
    border: solid 2px var(--primary-color);
    transition: all 0.3s ease;
  }
}

.bgparalax {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%);
  background-size: cover;
  background-repeat: no-repeat;
}

/* header */
#header-wrap {
  width: 100%;
  top: 0;
  left: 0;
  &.sticky {
    position: fixed;
    z-index: 10;
    transition: all 0.6s ease;
    .navbar {
      background: rgba(30, 31, 34, 0.9);
      padding: 0px;
      transition: all 0.3s ease;
      .navbar-item {
        padding: 14px 10px;
        transition: all 0.3s ease;
      }
      .menu > .navbar-item::before {
        color: rgba(255, 255, 255, 0);
        transition: all 0.3s ease;
      }
    }
  }
}

.navbar {
  padding: 15px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  border-bottom: solid 1px rgba(255, 255, 255, 0);
  z-index: 999;
  transition: all 0.3s ease;
  &.white {
    a {
      color: $black;
    }
    .btn {
      color: $white;
    }
  }
  .w-100-nav {
    margin: 0;
    width: 100%;
    justify-content: left;
    align-items: center;
    flex-wrap: nowrap;
  }
  a {
    position: relative;
    font-size: 14px;
    font-weight: 800;
    padding: 0 5px 0 0;
    text-decoration: none !important;
    color: $white !important;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: inherit;
      transition: all 0.3s ease;
    }
    &.active {
      transition: all 0.3s ease;
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        background: 0;
        cursor: default;
      }
    }
  }
  .navbar-title {
    margin-right: auto;
    font-size: 150%;
    padding: 12px 16px;
  }
  .navbar-item {
    position: relative;
    display: inline-block;
    padding: 14px 12px;
    height: max-content;
    cursor: default;
    transition: all 0.3s ease;
  }
  .breakpoint__xl-only {
    width: max-content;
  }
  .logo {
    width: max-content;
    padding-right: 30px;
    .navbar-item {
      position: relative;
      top: 2px;
      left: 0px;
      padding: 0;
      width: max-content;
      border-bottom: none;
      a {
        padding: 0;
        .d-3 {
          display: none;
        }
      }
    }
  }
  .menu {
    display: flex;
    justify-content: flex-end;
  }
  .menu > .navbar-item {
    counter-increment: my-awesome-counter;
  }
  // .menu > .navbar-item::before {
  //   content: counter(my-awesome-counter) "";
  //   position: absolute;
  //   right: 22px;
  //   top: 0px;
  //   font-weight: bold;
  //   font-size: 10px;
  //   color: rgba(255, 255, 255, 0.5);
  //   transition: all 0.3s ease;
  // }
  .nav-icon {
    display: none;
  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background: #1b1b1b;
    border-bottom: solid 1px #dddddd;
    transition: all 0.6s ease;
    &.white {
      background: $white;
      .btn {
        color: $black;
      }
      a {
        color: $black;
      }
    }
  }
  .mainside {
    width: max-content;
    padding-left: 0;
    padding-right: 0;
    a {
      text-align: center;
      color: #fff !important;
      background: #8364e2;
      border-radius: 6px;
      letter-spacing: normal;
      outline: 0;
      font-weight: 800;
      text-decoration: none;
      padding: 6px 20px;
      font-size: 14px;
      border: none;
      cursor: pointer;
      box-shadow: 2px 2px 20px 0px rgba(131, 100, 226, 0);
      transition: all 0.3s ease;
      &:hover {
        box-shadow: 2px 2px 20px 0px rgba(131, 100, 226, 0.5);
        transition: all 0.3s ease;
      }
    }
  }
  .btn-line {
    display: inline-block;
    font-family: var(--title-font);
    text-align: center;
    color: #fff;
    outline: 0;
    font-weight: bold;
    text-decoration: none;
    padding: 3px 20px 2px 20px;
    font-size: 14px;
    border: solid 2px var(--primary-color);
    text-transform: uppercase;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
  }
  a.btn-line,
  a#btn-line {
    display: inline-block;
    font-family: var(--title-font);
    text-align: center;
    color: #fff;
    background: none;
    outline: 0;
    font-weight: bold;
    text-decoration: none;
    padding: 3px 20px 2px 20px;
    font-size: 14px;
    border: none;
    text-transform: uppercase;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border: solid 2px var(--primary-color);
    transition: all 0.3s ease;
    &:hover {
      cursor: pointer;
      background: var(--primary-color);
      border: solid 2px var(--primary-color);
      transition: all 0.3s ease;
    }
  }
}

.navbar-item {
  pointer-events: auto;
}

/* dropdown */
.dropdown-custom.btn {
  color: $white !important;
  font-size: 14px;
  border: 0px;
  position: relative;
  top: -1px;
  overflow: unset !important;
  letter-spacing: normal;
  font-weight: 600;
  padding: 0 20px 0 0;
  background: none !important;
  &::before {
    content: "";
    position: absolute;
    top: -10px;
    left: 19px;
    width: max-content;
    height: auto;
    padding: 30px 40px;
    background: 0;
  }
  &::after {
    margin-left: 5px !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}
.dropdown-toggle::after {
  font-family: FontAwesome;
  content: "\f078";
  color: var(--primary-color);
  padding-left: 2px;
  font-size: 7px;
  position: absolute;
  top: 6px;
  right: 5px;
  border: none;
}
.item-dropdown {
  width: 190px;
  position: absolute;
  background: var(--bg-dark-1);
  border: solid 1px rgba(255, 255, 255, 0.1);
  overflow: hidden;
  inset: 39px auto auto 0;
  padding: 0 0;
  animation: smoothDrop 0.2s ease;
  -webkit-animation: smoothDrop 0.2s ease;
  box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  z-index: 1;
  .dropdown {
    position: relative;
    text-align: center;
    a {
      color: $white !important;
      text-transform: none;
      font-weight: normal;
      letter-spacing: normal;
      display: block;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 8px 20px 8px 20px;
      min-width: 210px;
      width: 100%;
      text-align: left;
      &:hover {
        color: $white !important;
        background: var(--secondary-color);
      }
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
}

/* swiper slider */
.mainslider {
  width: 100%;
  height: 100vh;
  .swiper-pagination {
    z-index: 1;
    bottom: 9% !important;
    right: 2% !important;

    &-bullet {
      display: none;
      float: right;
      font-size: 40px;
      color: #ffffff;

      span {
        color: var(--body-font-color);
        font-size: 20px !important;
      }
    }

    &-bullet-active {
      background: rgba(0, 0, 0, 0);
      display: block;
    }
  }
  p {
    color: #adb7be;
  }
}

.doubleslider {
  display: flex;

  .mainslider {
    width: 80%;
    height: 100vh;
    margin: 0;

    .swiper-button-prev {
      left: 20px;
    }

    .swiper-button-next {
      right: 20px;
    }
  }

  .thumb-slider {
    width: 20%;
    height: 97.5vh;
    padding: 0;
    margin: 10px;
    margin-right: 0px;

    .swiper-vertical {
      touch-action: pan-x;
    }

    .swiper-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      transition-property: transform;
      transition-timing-function: var(
        --swiper-wrapper-transition-timing-function,
        initial
      );
      box-sizing: content-box;
    }

    .swiper-slide {
      text-align: center;
      background-size: cover;
      background-position: center;
      background-color: var(--bg-dark-1);
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      opacity: 0.8;

      &.swiper-slide-thumb-active {
        opacity: 1;
      }
    }

    .sw-caption-thumb {
      h3 {
        font-size: 22px;
      }

      .d-tag {
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
        background: var(--primary-color);
        padding: 2px 10px;
        border-radius: 30px;
        -moz-border-radius: 30px;
        -webkit-border-radius: 30px;
      }
    }
  }
}

/* smallswiper */
.smallslider {
  width: 100%;
  height: auto;
  min-height: 50px;
  .swiper-pagination-bullet {
    display: block;
    font-size: 0%;
    float: none;
  }
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 8px)
    );
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 8px)
    );
    display: inline-block;
    border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
  }
  .swiper-pagination {
    position: relative;
    margin-top: 0px;
    bottom: 0;
    right: 0;
  }
  .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.3);
    opacity: 1 !important;
  }
  .swiper-pagination-bullet-active {
    background: var(--secondary-color);
  }
  .swiper-inner {
    position: relative;
  }
  .swiper-slide {
    width: 380px;
    height: auto;
  }
}

.swiper-pagination {
  z-index: 1;
  bottom: 9% !important;
  right: 2% !important;

  &-bullet-active {
    background: rgba(0, 0, 0, 0);
    display: block;
  }

  &-bullet {
    display: none;
    float: right;
    font-size: 40px;
    color: #ffffff;

    span {
      color: var(--body-font-color);
      font-size: 20px !important;
    }
  }
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 30px);
  width: 60px;
}

.swiper-inner {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  .align-items-center {
    align-items: center !important;
  }

  .sw-caption {
    z-index: 1;
    position: absolute;
    width: 100%;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
  }

  .subtitle {
    position: relative;
    display: inline-block;
    font-weight: bold;
    color: #ffffff;
    border: solid 2px rgba(255, 255, 255, 0.25);
    padding: 0 15px 2px 15px;
    padding-left: 15px;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;

    &.blink {
      padding-left: 35px;

      &::before {
        animation: blinkingText 1s infinite;
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        background: var(--primary-color);
        border-radius: 20px;
        -moz-border-radius: 20px;
        -webkit-border-radius: 20px;
        top: 7.5px;
        left: 10px;
        -webkit-box-shadow: 0 0 10px 0px rgba(var(--primary-color-rgb), 1);
        -moz-box-shadow: 0 0 10px 0px rgba(var(--primary-color-rgb), 1);
        box-shadow: 0 0 10px 0px rgba(var(--primary-color-rgb), 1);
      }

      @keyframes blinkingText {
        0% {
          background: var(--primary-color);
        }
        50% {
          background: rgba(255, 255, 255, 1);
        }
        100% {
          background: var(--primary-color);
        }
      }
    }
  }
}

h1.slider-title {
  font-size: 72px;
  background: -webkit-linear-gradient(0deg, #888888 0%, #ffffff 75%);
  background: -moz-linear-gradient(0deg, #888888 0%, #ffffff 75%);
  background: linear-gradient(0deg, #888888 0%, #ffffff 75%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sw-price {
  color: #ffffff;

  .d-starting {
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 0.75);
  }

  .d-price {
    font-size: 56px;
    font-family: var(--title-font);
    font-weight: bold;
    margin-bottom: 20px;
  }

  .d-cur {
    font-weight: bold;
    font-size: 16px;
    font-family: var(--body-font);
    color: var(--secondary-color);
  }

  .d-val {
    background: -webkit-linear-gradient(0deg, #888888 0%, #ffffff 75%);
    background: -moz-linear-gradient(0deg, #888888 0%, #ffffff 75%);
    background: linear-gradient(0deg, #888888 0%, #ffffff 75%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0 10px;
  }

  .d-period {
    font-size: 16px;
    font-family: var(--body-font);
    color: var(--secondary-color);
  }
}

/* home static */
.home {
  color: #adb7be;
}
.bgcolor {
  background: var(--bg-dark-1);
}

#content {
  background: none;
}

.d_wrap {
  position: relative;

  img {
    position: relative;
  }
}

.d_wrap_sm-box {
  font-size: 15px;
  font-family: var(--title-font);
  font-weight: bold;
  display: inline-block;
  position: absolute;
  background: var(--bg-dark-3);
  padding: 10px 15px;
  height: 62px;
  padding-left: 60px;
  color: #2f394b;
  box-shadow: 5px 25px 20px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 5px 25px 20px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 25px 20px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;

  i {
    position: absolute;
    left: 10px;
    margin-right: 10px;
    background: var(--primary-color);
    padding: 8px;
    width: 42px;
    height: 42px;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    text-align: center;
    color: #fff;
    font-size: 24px;
  }

  h3 {
    font-size: 24px;
    margin-bottom: 0px;
  }

  h6 {
    font-weight: 500;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
  }
}

.d_wrap_sm-box.b1 {
  left: 0;
  top: 20%;
}

.d_wrap_sm-box.b2 {
  right: 0;
  top: 25%;
}

.d_wrap_sm-box.b3 {
  right: 50%;
  top: 65%;
}

/* home static video */
.home-video {
  width: 100%;
  height: 100vh;
  position: relative;
  h1 {
    font-size: 72px;
    background: linear-gradient(0deg, #888888 0%, #ffffff 75%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .de-rating-ext {
    i {
      color: #f2b827;
      font-size: 14px;
      margin-right: 2px;
    }
    .d-val {
      color: #fff;
      margin-right: 10px;
    }
  }
  .overlay-bg {
    position: relative;
    z-index: 1;
  }
  .de-gradient-edge-top,
  .de-gradient-edge-bottom {
    z-index: 1;
  }
}
.iframeyoutube {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  div {
    div {
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

/* testimonial */
.de_testi {
  display: block;
  margin-bottom: 10px;
  blockquote {
    p {
      margin-top: 10px;
    }
    &,
    p {
      font-size: 16px;
      line-height: 1.7em;
      font-weight: normal;
    }
  }
  .de_testi_by {
    font-style: normal;
    font-size: 12px;
    display: flex;
    margin-top: -20px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
    img {
      width: 50px !important;
      height: 50px !important;
      margin-right: 10px;
      margin-top: 20px;
      border-radius: 100%;
      -moz-border-radius: 100%;
      -webkit-border-radius: 100%;
    }
  }
  .de_testi_pic {
    float: left;
    padding-right: 15px;
  }
  .de_testi_company {
    padding-top: 20px;
  }
  .adjustments__review{
    max-height: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  blockquote {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-family: var(--body-font);
    color: #ffffff;
    border: none;
    font-weight: 300;
    border-radius: 16px;
    padding: 40px 40px 30px 40px;
    background: var(--bg-dark-2);
    height: 310px;
    

    &::before {
      position: absolute;
      font-family: "FontAwesome";
      content: "\f10e";
      color: rgba(255, 255, 255, 0.05);
      z-index: 100;
      font-size: 84px;
      background: none;
      top: 20px;
      right: 0;
      padding: 20px;
      z-index: -1;
    }
    span {
      margin-top: 20px;
    }
  }
  &.no-bg {
    blockquote {
      background: none;
    }
  }
  span {
    margin-top: 20px;
  }
  .de-rating-ext i {
    color: #f2b827;
    font-size: 14px;
    margin-right: 1px;
  }
}
.de-rating-ext {
  i {
    color: #f2b827;
    font-size: 14px;
    margin-right: 5px;
  }
  .d-val {
    color: #fff;
    margin-right: 10px;
  }
  strong {
    color: #ffffff;
  }
}

.de_testi.type-2.review blockquote {
  padding: 50px;
}

.de_testi.type-2.review blockquote:before {
  display: none;
}

.de_testi.type-2.review p {
  padding-top: 0;
}

.de_testi.type-2.review h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}

.text-light .de_testi.type-2 blockquote {
  color: #fff;
  background: var(--bg-color-odd);
  border: var(--border-default);
}

.de_testi.type-2 blockquote p {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7em;
  letter-spacing: -0.1px;
}

.de_testi.type-2 .de_testi_by {
  font-size: 14px;
  font-weight: normal;
}

.de_testi.opt-3 blockquote {
  background: none;
  color: #555;
  padding-top: 0;
  font-weight: 400;
}

.de_testi.opt-3 blockquote p {
  font-size: 14px;
}

.de_testi.opt-3 blockquote:before {
  color: #fff;
}

.de_testi.opt-3 .de_testi_by {
  font-size: 14px;
  color: #000;
}

.de_testi.de-border blockquote {
  border: solid 5px rgba(0, 0, 0, 0.05);
}

#testimonial-masonry .item {
  margin-bottom: 30px;
}

.testimonial-list p {
  font-style: italic;
}

.testimonial-list,
.testimonial-list li {
  list-style: none;
  padding: 0px;
  font-size: 16px;
  line-height: 1.7em;
  padding-left: 30px;
}

.testimonial-list span {
  font-weight: bold;
  display: block;
  margin-top: 20px;
  font-size: 14px;
}

.testimonial-list.big-font li {
  font-size: 28px;
}

.testimonial-list:before {
  font-family: FontAwesome;
  content: "\f10d";
  font-size: 40px;
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  margin-left: -30px;
}

.testimonial-list.style-2,
.testimonial-list.style-2 li {
  padding-left: 0px;
}

.testimonial-list.style-2 {
  text-align: center;
}

.testimonial-list.style-2:before {
  margin: 0;
  position: static;
}

.testimonial-list.style-2 span {
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
}

/* server */
.de-server {
  list-style: none;
  padding: 0;
  li {
    font-family: var(--title-font);
    display: inline-block;
    font-size: 12px;
    padding: 8px 12px;
    border: solid 2px rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    line-height: 1em;
    margin: 0 6px 8px 0;
    a {
      text-decoration: none;
    }
  }
}

/* collection */
.de-gradient-edge-top {
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(
    180deg,
    rgba(30, 31, 34, 1) 0%,
    rgba(30, 31, 34, 0) 100%
  );
}
.de-gradient-edge-bottom {
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(
    0deg,
    rgba(30, 31, 34, 1) 0%,
    rgba(30, 31, 34, 0) 100%
  );
}
.de-item {
  border: solid 0px rgba(var(--primary-color-rgb), 0);
  position: relative;
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  overflow: hidden;
  transition: 0.5s;
  .d-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: -moz-linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0) 50%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0) 50%
    );
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0) 50%
    );
    .d-text {
      position: absolute;
      bottom: -50px;
      padding: 30px;
      width: 100%;
      transition: all 0.3s ease;
      .btn-main {
        margin-top: 10px;
        opacity: 0;
      }
      h4 {
        margin-bottom: 5px;
      }
      p {
        margin-bottom: 0;
      }
    }
    .d-label {
      position: absolute;
      font-size: 14px;
      top: 20px;
      right: 20px;
      padding: 0px 12px;
      color: #ffffff;
      font-weight: 800;
      border-radius: 6px;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      background-color: var(--tertiary-color);
    }
  }
  &:hover {
    border: solid 5px var(--primary-color);
    transition: 0.5s;
    box-shadow: 0 0 50px 0px rgba(0, 0, 0, 1);
    .d-overlay .d-text {
      bottom: 0;
      transition: all 0.3s ease;
      .btn-main {
        opacity: 1;
      }
    }
    img {
      transform: scale(1.05);
    }
  }
  .price {
    color: #ffffff;
    font-weight: bold;
    background: rgba(var(--primary-color-rgb), 0.5);
    margin-left: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    padding: 0 5px;
  }
}

/* accordion */
.accordion-item {
  background: rgba(255, 255, 255, 0.05);
  background-repeat: repeat;
  background-size: auto;
  border: none;
  color: var(--body-font-color);
  margin-bottom: 10px;
  border-radius: 10px !important;
  padding: 10px 10px 10px 20px;
  h3 {
    font-family: var(--body-font);
    padding: 0 !important;
    margin: 0 !important;
  }
  .accordion-button {
    font-size: 17px;
    background: none;
    padding: 5px 0;
    margin: 0;
    color: #ffffff;
    font-weight: 600;
    line-height: 0;
    &:before {
      content: "";
      position: absolute;
      right: 0px;
      top: 0px;
      width: 30px;
      height: 30px;
      border-radius: 8px !important;
      background: linear-gradient(
        to right,
        var(--primary-color),
        var(--secondary-color)
      );
    }
    &:after {
      margin-right: 4px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      z-index: 1;
    }
    &:focus {
      z-index: 3;
      border-color: 0px;
      outline: 0;
      box-shadow: none;
    }
  }
  .accordion-body {
    padding: 15px 0;
  }
}
.accordion-button:not(.collapsed) {
  box-shadow: none;
}

/* location map */
.de-map-hotspot {
  position: relative;

  img {
    width: 100%;
  }
}

.de-spot {
  padding: 0;
  margin: 0;
  position: absolute;
  vertical-align: center;
  text-align: center;

  span {
    position: absolute;
    display: inline-block;
    background: #ffffff;
    padding: 0 10px;
    font-size: 12px;
    font-weight: bold;
    left: 30px;
    line-height: 1.8em;
    color: #35404e;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
  }
}

.dark-scheme {
  .de-spot {
    span {
      background: none;
      color: #ffffff;
      border: solid 2px rgba(255, 255, 255, 0.25);
    }
  }
}

.de-circle-1 {
  width: 15px;
  height: 15px;
  background-color: var(--tertiary-color);
  border-radius: 50%;
  position: absolute;
  margin: 5px 0 0 5px;
  left: 31%;
}

.de-circle-2 {
  border: 4px solid var(--tertiary-color);
  border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  animation: pulsate 1s ease-out infinite;
  opacity: 0;
  left: 31%;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

/* marquee */
.d-flex {
  display: flex;
  white-space: nowrap;
}

.de-marquee-list {
  display: flex;
  align-items: top;
  -webkit-animation: loop 40s infinite linear;
  animation: loop 40s infinite linear;

  .d-item-txt {
    font-size: 100px;
    line-height: 100px;
    padding-right: 8px;
    font-style: normal;
    font-weight: bold;
    font-family: var(--title-font);
    color: var(--primary-color);
    user-select: none;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.75);
    img {
      width: 80px;
      height: auto;
      margin-top: -15px;
      margin-right: 20px;
    }
  }

  &.s2 {
    .d-item-txt {
      background: -webkit-linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 100%
      );
      background: -moz-linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 100%
      );
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 0;
    }
    .d-item-block {
      height: 5px;
      background: -webkit-linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 100%
      );
      background: -moz-linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 100%
      );
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 100%
      );
    }
  }

  .d-item-txt img {
    width: 80px;
    margin-top: -15px;
    margin-right: 20px;
  }

  .d-item-display {
    display: inline;
  }

  .d-item-block {
    width: 80px;
    height: 2px;
    margin: 0 20px;
    margin-right: 40px;
    padding: 0px;
    border-radius: 50%;
    display: inline-block;
    transform: translateY(-30px);
    background: var(--primary-color);

    &.text-light {
      background: rgba(255, 255, 255, 0.3);
    }
  }

  &.style-2 {
    .d-item-txt {
      font-size: 160px;
      background: rgba(255, 255, 255, 0.2);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .d-item-dot {
      transform: translateY(-0px);
      background: rgba(255, 255, 255, 0.2);
    }
  }

  width: 100%;
  overflow: hidden;
  white-space: nowrap;

  .d-item {
    display: inline-block;
    padding-right: 10%;
    animation: marquee 45s linear infinite;

    @keyframes marquee {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }
}

/* pricelist */
.switch-set {
  font-weight: bold;
  direction: ltr !important;

  .dark-scheme & {
    color: #ffffff;
  }
}

.dark-scheme {
  .switch-set {
    color: #ffffff;
  }

  .switch {
    background: rgba(255, 255, 255, 0.1);
  }
}

.switch-set div {
  display: inline-block;
  padding: 0 5px;
  direction: ltr !important;
}

.switch {
  margin-bottom: -5px;
  -webkit-appearance: none;
  height: 22px;
  width: 42px;
  background-color: #f4f4f4;
  border-radius: 60px;
  position: relative;
  cursor: pointer;
  border: none;

  &.checked::after {
    left: 20px;
    transition: all 0.3s ease;
  }

  &::after {
    content: "";
    width: 18px;
    height: 18px;
    background-color: #ffffff;
    position: absolute;
    border-radius: 100%;
    transition: 0.5s;
    margin: 2px 0 0 2px;
    left: 0px;
    transition: all 0.3s ease;
  }
}

.de-switch {
  display: block;

  h3 {
    font-weight: 400;
    padding-bottom: 6px;
  }

  input[type="checkbox"] {
    display: none;

    &:checked + label {
      background-color: #2f7df9;

      &:after {
        left: 23px;
      }
    }
  }

  label {
    transition: all 200ms ease-in-out;
    display: inline-block;
    position: relative;
    height: 20px;
    width: 40px;
    border-radius: 40px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
    color: transparent;

    &:after {
      transition: all 200ms ease-in-out;
      content: " ";
      position: absolute;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background-color: white;
      top: 3px;
      left: 3px;
      right: auto;
    }
  }

  .dark-scheme & label {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

/* de_pricing-table */
.de_pricing-table {
  position: relative;
  height: 100%;
  padding: 40px;
  padding-bottom: 10px;
  .d-title {
    font-family: var(--title-font);
    font-weight: bold;
    font-size: 40px;
    color: #ffffff;
    text-align: center;
  }
  .d-stars {
    text-align: center;
    i {
      margin: 0 3px;
      font-size: 14px;
      color: #ff5000;
    }
  }
  .d-item {
    font-size: 26px;
    font-weight: bold;
    padding: 15px 0 10px 0;
    border: solid 2px rgba(255, 255, 255, 0.25);
    text-align: center;
    border-radius: 10px;
    color: #fff;
    span {
      font-size: 14px;
      display: block;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.de_pricing-table.type-2.rec {
  border-color: var(--tertiary-color) !important;
}

.de_pricing-table .d-head,
.de_pricing-table .d-group,
.de_pricing-table .d-action {
  margin-bottom: 30px;
}

.de_pricing-table .d-head h3,
.de_pricing-table .d-head p {
  margin-bottom: 0px;
}

.de_pricing-table .d-head h3 {
  margin-top: 10px;
  text-align: center;
  min-width: 100%;
  font-size: 22px;
  margin-bottom: 10px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  padding: 5px 20px 0 20px;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}

.de_pricing-table .d-head p {
  line-height: 1.4em;
  min-height: 50px;
}

.de_pricing-table .d-price h4 {
  font-size: 40px;
  margin-bottom: 5px;
  letter-spacing: -1px;
}
.de_pricing-table {
  .opt-1 {
    display: block;
  }
  .opt-2 {
    display: none;
  }
  &.active {
    .opt-1 {
      display: none;
    }
    .opt-2 {
      display: block;
    }
  }
}

.de_pricing-table .d-price h4 span {
  font-size: 20px;
  color: var(--secondary-color);
}

.de_pricing-table h4 {
  font-size: 16px;
  margin-bottom: 5px;
}

.de_pricing-table .d-action p {
  font-size: 12px;
}

.de_pricing-table .d-list {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  font-size: 15px;
}

.de_pricing-table .d-list li {
  padding-left: 25px;
}

.de_pricing-table .d-list li:before {
  position: absolute;
  left: 0;
  font-family: "FontAwesome";
  content: "\f00c";
  color: #ffffff;
  margin-right: 12px;
  margin-top: 7px;
  width: 16px;
  height: 16px;
  line-height: 1.2em;
  padding: 2px 0;
  font-size: 10px;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.de_pricing-table .d-list li.no {
  opacity: 0.5;
}

.de_pricing-table .d-list li.no:before {
  content: "\f00d";
  background: #aaa;
}

.de_pricing-table .d-recommend {
  position: absolute;
  top: 0;
  width: 100%;
  background: var(--tertiary-color);
  left: 0;
  text-align: center;
  color: #fff;
  font-weight: bold;
}

.de_pricing-tables {
  border: solid 1px #dddddd;
  border-bottom: none;
}

.de_pricing-table.type-2 {
  background: #ffffff;
  border: solid 3px rgba(var(--primary-color-rgb), 0.2);
  overflow: hidden;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
}

.de_pricing-table.type-2.table-rec {
  border: solid 3px rgba(var(--secondary-color-rgb), 1);
}

.dark-scheme .de_pricing-tables {
  border: solid 1px rgba(255, 255, 255, 0.05);
  border-bottom: none;
}

.dark-scheme .de_pricing-table.type-2 {
  background: rgba(255, 255, 255, 0.025);
  border: solid 2px rgba(255, 255, 255, 0.1);
}

.de_pricing-tables div:last-child .de_pricing-table.type-2 {
  border-right: none;
}

.flags {
  margin: 10px 0;
  button#rfs-btn {
    border: solid 1px rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    span {
      span {
        color: #fff;
      }
    }
    &.ReactFlagsSelect-module_selectBtn__19wW7::after {
      border-top: 5px solid #fff;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 0;
    }
    .ReactFlagsSelect-module_selectFlag__2q5gC {
      font-size: 1.6em;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}

.table-pricing {
  border-radius: 8px;
  overflow: hidden;
}

.table-pricing i {
  font-size: 16px;
  position: relative;
  margin-right: 10px;
}

.table-pricing.dark-style {
  color: var(--dark-body-font-color);
  background: var(--bg-dark-3);
  .flagstable {
    width: 70% !important;
    margin: 0 auto;
    button {
      color: #fff;
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
    ul {
      background: #333;
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}
.table > :not(caption) > * > *,
.table-pricing thead {
  color: var(--dark-body-font-color);
  font-size: 16px;
}
.table-pricing thead,
.bg-gradient-to-top-right,
.p-tagline,
h5.title {
  background: linear-gradient(
    to right,
    var(--primary-color),
    var(--secondary-color)
  );
}
.table-pricing thead tr th {
  font-weight: bold;
  padding: 20px 0 20px 0;
  color: #fff;
}
.table-pricing > :not(caption) > * > * {
  border: none;
  border-bottom-color: currentcolor;
  background: none;
}
.table-pricing.dark-style tbody tr {
  background: var(--bg-color-2);
  th {
    border: none;
    padding: 30px 0 0 0;
  }
  td {
    border: none;
    padding: 30px 0 0 0;
    .btn-main {
      width: 60%;
      margin: 0 auto;
    }
  }
}
.table-pricing.dark-style tbody tr:nth-child(2n) {
  background: var(--bg-dark-2);
  color: var(--dark-body-font-color);
}

/* blog list */
.bloglist {
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}
.post-content {
  position: relative;
  p {
    margin-bottom: 0px;
    font-size: 15px;
  }
}
.post-image {
  position: relative;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  .d-tagline {
    position: absolute;
    top: 20px;
    right: 20px;
    line-height: 1em;
    span {
      font-size: 12px;
      color: #ffffff;
      text-transform: uppercase;
      display: inline-block;
      background: rgba(0, 0, 0, 0.25);
      margin-left: 5px;
      padding: 4px 10px 4px 10px;
      border-radius: 30px;
      -moz-border-radius: 30px;
      -webkit-border-radius: 30px;
    }
  }
  img {
    width: 100%;
    height: auto;
    margin-bottom: 0px;
  }
}
.post-text {
  .d-date {
    font-size: 14px;
  }
  h4 a {
    font-size: 20px;
    font-weight: bold;
    font-family: var(--body-font);
    color: #fff;
    text-decoration: none;
    line-height: 1.5em;
    letter-spacing: 0;
  }
}

/* pagination list */
.pagination a {
  color: #ffffff;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.5s;
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0 4px;
  font-size: 15px;
}

.pagination a.active {
  background-color: var(--primary-color);
  color: #ffffff;
  border: 1px solid var(--primary-color);
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.dark-scheme .pagination li:not(.active) a {
  border-color: rgba(255, 255, 255, 0.1);
  background: none;
  color: #ffffff;
}

.pagination li:last-child a {
  border-right: solid 1px #d2d2d2;
}

.dark-scheme .pagination li:last-child a {
  border-right-color: rgba(255, 255, 255, 0.1);
}

.pagination > .active > a {
  color: #ffffff;
}

/* to top */
#scroll-to-top {
  cursor: pointer;
  width: max-content;
  height: max-content;
  position: fixed;
  right: 10px;
  z-index: 999;
  &.init {
    bottom: -60px;
    transition: all 0.3s ease;
  }
  &.show {
    bottom: 15px;
    transition: all 0.3s ease;
  }
  .float-text {
    width: 20px;
    position: fixed;
    z-index: 1002;
    margin-left: 30px;
    text-align: center;
    letter-spacing: 2px;
    font-size: 12px;
    top: 50%;
    left: 15px;
    transform: translate(-50%, -50%);
    span {
      font-weight: 400;
      margin-left: -5px;
      margin-top: 20px;
      writing-mode: vertical-rl;
      letter-spacing: 0.75px;
      -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      transform: rotate(-180deg);
      color: #fff;
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
  .scrollbar-v {
    background: var(--primary-color);
    position: fixed;
    top: calc(50% + 60px);
    left: 8px;
    width: 2px;
    transition: all linear 0.1s;
    min-height: 15%;
    z-index: 1000;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 2px;
      min-height: 100px;
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

/* knowledge */
#form_sb input[type="text"] {
  font-size: 20px;
  padding: 25px 20px 25px 20px;
  width: 85%;
  height: 30px;
  float: left;
  display: table-cell;
  border-radius: 30px 0 0 30px;
  -moz-border-radius: 30px 0 0 30px;
  -webkit-border-radius: 30px 0 0 30px;
  border: none;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  border: none;
}
#form_sb input[type="text"]::-moz-input-placeholder {
  color: var(--body-font-color);
}
#form_sb input[type="text"]::-webkit-input-placeholder {
  color: var(--body-font-color);
}
#form_sb input[type="text"]::placeholder {
  color: var(--body-font-color);
}
#form_sb .form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0);
  
}

#form_sb #btn-submit i {
  text-align: center;
  font-size: 30px;
  float: left;
  width: 15%;
  background: var(--secondary-color);
  color: #ffffff;
  display: table-cell;
  padding: 10px 0 10px 0;
  border-radius: 0 30px 30px 0;
  -moz-border-radius: 0 30px 30px 0;
  -webkit-border-radius: 0 30px 30px 0;
}
.de-box-cat {
  padding: 30px;
  background: rgba(255, 255, 255, 0.05);
  background-repeat: repeat;
  background-size: auto;
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  a {
    text-decoration: none;
  }
  i {
    color: rgba(255, 255, 255, 0.25);
    font-size: 40px;
    margin-bottom: 15px;
    transition: 0.5s;
  }
  &:hover i {
    color: #ffffff;
    transition: 0.5s;
  }
  span {
    color: var(--secondary-color);
  }
}
.box-long {
  background: rgba(255, 255, 255, 0.1);
}

/* footer section */
footer {
  font-family: var(--body-font);
  background: rgba(255, 255, 255, 0.025);
  padding: 70px 0 0 0;
  font-size: 15px;
  .widget ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .menu-simple {
    display: inline;
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: 500;
    li {
      display: inline;
      margin: 0 15px;
      margin-left: 15px;
    }
  }
}

footer.footer-light {
  background: #ffffff;
  #form_subscribe.form-dark input[type="text"] {
    color: #ffffff;
    background: rgba(255, 255, 255, 0.1);
  }
}

#form_subscribe input[type="text"] {
  padding: 7px 12px 7px 12px;
  width: 80%;
  float: left;
  display: table-cell;
  border-radius: 30px 0 0 30px;
  -moz-border-radius: 30px 0 0 30px;
  -webkit-border-radius: 30px 0 0 30px;
  border: none;
  border-right: none;
  background: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
  -moz-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
  box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
  &::placeholder {
    color: var(--body-font-color);
  }
}

footer:not(.footer-light)
  #form_subscribe
  input[type="text"]::-moz-input-placeholder {
  color: var(--body-font-color);
}

footer:not(.footer-light)
  #form_subscribe
  input[type="text"]::-webkit-input-placeholder {
  color: var(--body-font-color);
}

footer:not(.footer-light) #form_subscribe.form-dark input[type="text"] {
  color: var(--body-font-color);
  background: rgba(255, 255, 255, 0.1);
}

.footer-light #form_subscribe input[type="text"] {
  border: none;
  border-right: none;
  padding: 6px 12px;
  background: #ffffff;
}

#form_subscribe input[type="text"]:focus {
  background: rgba(255, 255, 255, 0.2);
}

#form_subscribe #btn-subscribe i {
  text-align: center;
  font-size: 28px;
  float: left;
  width: 20%;
  background: linear-gradient(
    to right,
    var(--primary-color),
    var(--secondary-color)
  );
  color: #ffffff;
  display: table-cell;
  padding: 5px 0 5px 0;
  border-radius: 0 30px 30px 0;
  -moz-border-radius: 0 30px 30px 0;
  -webkit-border-radius: 0 30px 30px 0;
}

footer.footer-light a {
  color: #5d6371;
}

footer p {
  line-height: 1.8em;
}

.dark-scheme footer a {
  color: var(--dark-body-font-color);
}

footer.footer-black {
  background: #222222;
}

footer h5,
.de_light footer h5 {
  color: #fff;
  font-size: 18px;
  text-transform: none;
}

footer a {
  color: #ffffff;
  text-decoration: none !important;
}

.de-navbar-left footer {
  padding: 70px 70px 0 70px;
}

.subfooter {
  margin-top: 20px;
  border-top: solid 1px rgba(255, 255, 255, 0.1);
  padding: 20px 0 20px 0;
}

.footer-light .subfooter {
  border-top: solid 1px rgba(0, 0, 0, 0.1);
}

.de-navbar-left .subfooter {
  background: none;
}
.social-icons {
  display: inline-block;
}

.social-icons i {
  text-shadow: none;
  color: var(--secondary-color);
  padding: 10px 5px;
  width: 36px;
  height: 36px;
  text-align: center;
  font-size: 16px;
  margin: 0 3px 0 3px;
  background: #ffffff;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  &:hover {
    color: var(--primary-color);
  }
}

.dark-scheme .social-icons i {
  background: rgba(255, 255, 255, 0.1);
}

.social-icons i.fa-google-plus {
  padding: 12px 14px 10px 6px;
}

.social-icons i:hover {
  background: #fff;
  border-color: #eceff3;
  color: var(--primary-color);
}

/* contact */
p.lead {
  color: rgba(255, 255, 255, 0.75);
  margin-bottom: 20px;
  a {
    text-decoration: none;
  }
}
#contact_form input[type="text"],
#contact_form textarea {
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
  border: solid 2px rgba(255, 255, 255, 0.2);
  height: auto;
  background: rgba(34, 34, 34, 0);
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  resize: none;
}
#contact_form :focus {
  outline: none;
  box-shadow: none;
}
#contact_form textarea {
  height: 259px;
  &::placeholder {
    color: var(--body-font-color);
  }
}
#contact_form {
  .field-set .d-label {
    display: inline-block;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 10px;
  }
  input[type="text"]::-moz-input-placeholder {
    color: var(--body-font-color);
  }
  input[type="text"]::-webkit-input-placeholder {
    color: var(--body-font-color);
  }
  input[type="text"]::placeholder {
    color: var(--body-font-color);
  }
  #success,
  #failed {
    display: none;
    &.show {
      display: block;
    }
  }
  #send_message {
    display: inline-block;
    font-family: var(--title-font);
    text-align: center;
    color: #fff;
    background: linear-gradient(
      to right,
      var(--primary-color),
      var(--secondary-color)
    );
    outline: 0;
    font-weight: bold;
    text-decoration: none;
    padding: 3px 20px 2px 20px;
    font-size: 14px;
    border: none;
    text-transform: uppercase;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
  }
}

/* about */
.justify {
  text-align: justify;
}
.images-deco-1 {
  .d-img-1 {
    position: relative;
    z-index: 1;
    width: 90%;
    margin: 5%;
    height: auto;
  }

  .d-img-2 {
    width: 30%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: auto;
  }

  .d-img-3 {
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: -12px;
    right: 0;
    margin: 5%;
  }
}

.year-card {
  display: flex;
  align-items: center;

  h1 {
    margin: 0;
    margin-right: 0;
    margin-right: 20px;
    font-size: 140px;
    float: left;
    position: relative;
    line-height: 1em;
    font-weight: 500;
    background: linear-gradient(0deg, #888888 0%, #ffffff 75%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .atr-desc {
    margin-top: -20px;
    color: #ffffff;
  }
}

.f-profile {
  margin-bottom: 0px;
  &:hover .fpwo-wrap {
    bottom: 30px;
    transition: all 0.3s ease;
  }
}

.f-profile h4 {
  margin-top: 20px;
  margin-bottom: 0px;
}

.fp-wrap {
  position: relative;
  width: 216px;
  height: 216px;
  margin: 0 auto;
  object-fit: cover;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  overflow: hidden;
  img {
    transform: scale(1);
    transition: all 0.3s ease;
  }
  &:hover img {
    transform: scale(1.05);
    transition: all 0.3s ease;
  }
}

.fpw-overlay {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.fpw-overlay-btm {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.f-invert {
  &.fpw-overlay-btm {
    opacity: 0;
  }
}

.fpwo-wrap {
  width: 100%;
  position: absolute;
  bottom: -50px;
  text-align: center;
  transition: all 0.3s ease;

  .f-profile:hover & {
    bottom: 30px;
  }
}

.fpwow-icons {
  display: inline-block;
  background: #ffffff;
  padding: 5px 10px 7px 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;

  a {
    color: #ffffff;
    padding: 0 5px;
  }

  i {
    font-size: 15px;
  }
}

.fp-icon {
  margin-top: 20%;
  z-index: 2;
  opacity: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  position: absolute;
  display: table;
  text-align: center;

  i {
    display: none;
    vertical-align: middle;
    display: table-cell;
    color: #fff;
    font-size: 36px;
    color: #ffffff;
    padding: 13px 0;
    background: rgba(var(--primary-color-rgb), 0.8);
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
  }
}

.de_count h3 {
  font-size: 72px;
  letter-spacing: 0.1px;
  margin: 0;
  margin-bottom: 0px;
  padding: 0;
  margin-bottom: 10px;
  line-height: 1em;
}

.text-line {
  color: transparent !important;
  -webkit-text-stroke: 1px #ffffff;
}

.de-step-s1 {
  position: relative;
  padding: 45px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  height: 100%;
  background: -moz-linear-gradient(
    45deg,
    rgba(var(--primary-color-rgb), 0.1) 0%,
    rgba(var(--secondary-color-rgb), 0.3) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(var(--primary-color-rgb), 0.1) 0%,
    rgba(var(--secondary-color-rgb), 0.3) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(var(--primary-color-rgb), 0.1) 0%,
    rgba(var(--secondary-color-rgb), 0.3) 100%
  );
}

.de-step-s1 p {
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}

.de-step-s1 h4 {
  position: relative;
  z-index: 1;
}

.de-step-s1 .d-number {
  font-size: 150px;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 70px;
  color: rgba(var(--secondary-color-rgb), 0.5);
}

/* login */
.rounded-10 {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
.shadow-soft {
  -webkit-box-shadow: 0 10px 30px rgb(0 19 87 / 6%);
  -moz-box-shadow: 0 10px 30px rgb(0 19 87 / 6%);
  box-shadow: 0 10px 30px rgb(0 19 87 / 6%);
}
.bg-dark-1 {
  background: var(--bg-dark-1) !important;
}
.bg-dark-2 {
  background: var(--bg-dark-2) !important;
}
.bg-dark-3 {
  background: var(--bg-dark-3) !important;
}

#login {
  .form-border input[type="text"] {
    padding: 8px;
    margin-bottom: 10px;
    border: none;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    height: auto;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    color: #ffffff;
    border: solid 2px rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.025);
  }
  .title-line {
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-size: 14px;
    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background: rgba(255, 255, 255, 0.2);
      margin-right: 20px;
    }
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: rgba(255, 255, 255, 0.2);
      margin-left: 20px;
    }
  }
  .btn-sc {
    width: 100%;
    display: inline-block;
    font-family: var(--title-font);
    font-weight: bold;
    padding: 5px 20px 3px 20px;
    font-size: 14px;
    color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    background: var(--bg-dark-3);
    transition: all 0.3s ease;
    img {
      width: 20px;
      margin-top: -4px;
      margin-right: 8px;
      height: auto;
    }
    &:hover {
      color: #ffffff;
      background: var(--primary-color);
      transition: all 0.3s ease;
    }
  }
}

.bg__wrapper {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/*** media query ***/
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1300px;
  }
  .float-text {
    display: block;
  }
  .mobilemenu,
  .mobile {
    display: none;
  }
}
@media only screen and (max-width: 1280px) {
  .float-text {
    display: none;
  }
}
@media only screen and (max-width: 1199px) {
  .dekstop {
    display: none;
  }
  .burgermenu {
    display: block;
    z-index: 1;
  }
  .navbar-brand {
    z-index: 1;
  }
  .sm-hide {
    display: none;
  }
  #header-wrap {
    position: fixed;
    background: var(--bg-dark-1);
    z-index: 10;
    &.sticky {
      .navbar {
        background: var(--bg-dark-1);
        padding: 12px 0;
        transition: all 0.3s ease;
      }
    }
    .navbar {
      background: var(--bg-dark-1);
      padding: 12px 0;
      transition: all 0.3s ease;
      .btn-line {
        right: 30px;
      }
      .navbar-brand {
        margin: 0;
        padding: 0;
      }
    }
    .container {
      display: block;
      max-width: 100%;
      padding: 0 15px;
      margin: 0;
      position: relative;
    }
    .menu_side_area {
      margin-left: 20px;
      width: max-content;
      position: fixed;
      top: 10px;
      right: 0;
      text-align: right;
      z-index: 0;
      display: flex;
      z-index: 99;
      .burgermenu {
        right: 15px;
        top: 0;
      }
    }

    
    .mobile {
      position: relative;
      width: 100%;
      height: max-content;
      top: 0;
      left: 0;
      padding: 0 15px;
      background: var(--bg-dark-1);
      .menu {
        display: block;
        padding-top: 30px;
        .navbar-item {
          display: block;
          padding: 10px 0px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          &::before {
            content: "";
          }
          .dropdown-custom.btn {
            width: 100%;
            text-align: left;
          }
          .dropdown-toggle::after {
            color: #fff;
            font-size: 10px;
          }
          .item-dropdown {
            width: 100%;
            position: relative;
            inset: 0px auto auto 0;
            border: 0;
            .dropdown a {
              padding: 8px 0px;
              margin-left: 5%;
              border: 0;
              border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .dark-scheme section {
    h2 {
      font-size: 40px;
    }
  }
  .sm-padding40 {
    padding: 40px;
  }
  .mb-sm-30 {
    margin-bottom: 30px;
  }
  .swiper-container {
    slidesperview: 2;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
    // height: 30px;
  }
  .de-spot .de-circle-1,
  .de-spot .de-circle-2 {
    transform: scale(0.25);
  }
  .de-spot span {
    display: none;
  }
  .swiper-container {
    slidesperview: 1;
  }
  footer .widget {
    margin-bottom: 15px;
  }
  footer .spacer-30 {
    margin-bottom: 15px;
  }
  footer .subfooter {
    text-align: center;
  }
  .hide-on-mobile {
    display: none;
  }
  .mobile__slider {
    width: 100% !important;
  }
}


